import { createSelector } from '@ngrx/store';
import { getPlatformState, PlatformState } from '../index';
import { GQLFile, GQLProject } from '@schemas';
import { ProjectsState } from './projects.reducer';

export const getProjectsState = createSelector(
  getPlatformState,
  (state: PlatformState) => state.projectState,
);

// project
export const getProjectsList = createSelector(
  getProjectsState,
  (state: ProjectsState) => {
    return state.projectsList.items || [];
  },
);

export const getIsLoadingProjects = createSelector(
  getProjectsState,
  (state: ProjectsState) => {
    return state.isLoadingProjects;
  },
);

export const getProject = createSelector(
  getProjectsState,
  (state: ProjectsState) => {
    return state.project || null;
  },
);

export const isProjectDisabledForAddingFiles = createSelector(
  getProject,
  (project: GQLProject | null): boolean => {
    const treadList = project?.threads?.items || [];
    const canAddFileWithoutThread = project?.canAddFileWithoutThread;
    if (treadList.length > 0) {
      return (
        treadList.every((thread) => !thread.canAddFile) &&
        !canAddFileWithoutThread
      );
    }
    return false;
  },
);

export const canAddFileWithoutThread = createSelector(
  getProject,
  (project: GQLProject | null): boolean => {
    return !!project?.canAddFileWithoutThread;
  },
);

export const getProjectsCount = createSelector(
  getProjectsState,
  (state: ProjectsState): number => {
    return state.projectsList?.total || 0;
  },
);

export const getProjectMainInfo = createSelector(
  getProjectsState,
  (state: ProjectsState) => {
    return {
      id: state.project?.id,
      title: state.project?.title,
    };
  },
);

export const getProjectListView = createSelector(
  getProjectsState,
  ({ projectListView }) => projectListView,
);

export const createEditProjectLoading = createSelector(
  getProjectsState,
  ({ projectCreating }) => projectCreating,
);

export const getProjectInvitations = createSelector(
  getProjectsState,
  (state) => state.project?.invitations || [],
);

export const selectOpenProjectIdUsersPopup = createSelector(
  getProjectsState,
  (state) => state.openMiniUsersPopupId,
);

// common
export const getProjectsTitles = createSelector(
  getProjectsState,
  (state: ProjectsState) => {
    return state.projectsTitles.items || [];
  },
);

export const getCurrentProjectId = createSelector(
  getProjectsState,
  (state: ProjectsState) => {
    return state.currentProjectId;
  },
);

export const getCurrentProjectFiles = createSelector(
  getProjectsState,
  (state: ProjectsState): GQLFile[] => {
    return state.project?.files.items || [];
  },
);

export const getProjectTitle = createSelector(
  getProjectsState,
  (state: ProjectsState) => {
    return state.project?.title || '';
  },
);
