import { HttpClient } from '@angular/common/http';
import { Injectable, Query } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { QueryRef } from 'apollo-angular';
import { forkJoin, merge, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { GET_ME, GET_PROJECT_USERS } from '@queries';
import { BaseApi } from '../../services/base-api';
import { loadNotifications } from '../../../notifications/store/notifications.actions';
import {
  editThreadSuccess,
  loadThreadSuccess,
} from '../threads/threads.actions';
import { UsersService } from './services/users.service';
import {
  loadCurrentUser,
  loadCurrenUserFailure,
  loadCurrenUserSuccess,
  loadProjectUsers,
  loadProjectUsersFailure,
  loadProjectUsersSuccess,
  loadThreadUsersSuccess,
  loadWorkspaceUsers,
  loadWorkspaceUsersFailure,
  loadWorkspaceUsersSuccess,
  setCurrenUserPermissionsSuccess,
  updateMyPassword,
  updateMyPasswordFailure,
  updateMyPasswordSuccess,
  updateMyProfileInfo,
  updateMyProfileInfoFailure,
  updateMyProfileInfoSuccess,
  updateUserProfileInfo,
  updateUserProfileInfoFailure,
  updateUserProfileInfoSuccess,
  loadCurrentUserRolePermissions,
  loadCurrentUserRolePermissionsFailure,
  loadCurrentUserRolePermissionsSuccess,
  updateUserProfileWithPassword,
  updateUserProfileWithPasswordSuccess,
  updateUserProfileWithPasswordFailure,
  checkInvitationStatus,
  checkInvitationStatusSuccess,
  checkInvitationStatusFailure,
} from './users.actions';
import { IgraphQLErrorsObject } from '../../types/IgraphQLErrorsObject';
import { GQLInvitationActionStatusEnum } from '../../../../schemas';
import { Router } from '@angular/router';

@Injectable()
export class UsersEffects {
  constructor(
    public actions$: Actions,
    public http: HttpClient,
    private api: BaseApi,
    private usersService: UsersService,
    private router: Router,
  ) {}

  loadCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrentUser),
      switchMap(() =>
        this.api.apollo
          .query<QueryRef<Query>>({
            query: GET_ME,
            fetchPolicy: 'network-only',
          })
          .pipe(
            mergeMap(({ data }: any) => [
              // TODO REFACTOR: remove any
              loadCurrenUserSuccess({ profile: data.me }),
              setCurrenUserPermissionsSuccess({
                permissions: JSON.parse(data.me.permissions),
              }),
              loadNotifications({ unreadOnly: false }),
              loadWorkspaceUsers(),
            ]),
            catchError((error: unknown) =>
              of(loadCurrenUserFailure({ error })),
            ),
          ),
      ),
    ),
  );

  loadCurrentUserRolePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrentUserRolePermissions),
      switchMap(() =>
        this.usersService.getCurrentUserPermissions().pipe(
          map((permissions) =>
            loadCurrentUserRolePermissionsSuccess({ permissions }),
          ),
          catchError((error: unknown) =>
            of(loadCurrentUserRolePermissionsFailure({ error })),
          ),
        ),
      ),
    ),
  );

  editUserMainInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMyProfileInfo),
      switchMap(({ input }) =>
        this.usersService.editMyProfile(input).pipe(
          map(({ profile }) => updateMyProfileInfoSuccess({ profile })),
          catchError((error: unknown) =>
            of(updateMyProfileInfoFailure({ error })),
          ),
        ),
      ),
    ),
  );

  updateUserProfileWithPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserProfileWithPassword),
      switchMap(({ profileInput, passwordInput }) =>
        forkJoin([
          this.usersService.editMyProfile(profileInput),
          this.usersService.editMyPassword(passwordInput),
        ]).pipe(
          map(([{ profile }]) =>
            updateUserProfileWithPasswordSuccess({ profile }),
          ),
          catchError((error: IgraphQLErrorsObject) =>
            of(updateUserProfileWithPasswordFailure({ error })),
          ),
        ),
      ),
    ),
  );

  editUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserProfileInfo),
      exhaustMap(({ input }) =>
        merge(
          ...input.map((profile) => {
            return this.usersService.editUserProfile(profile).pipe(
              map(() => updateUserProfileInfoSuccess()),
              catchError((error: unknown) =>
                of(updateUserProfileInfoFailure({ error, profile })),
              ),
            );
          }),
        ),
      ),
    ),
  );

  editUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMyPassword),
      switchMap(({ input }) =>
        this.usersService.editMyPassword(input).pipe(
          map((me) => updateMyPasswordSuccess({ me })),
          catchError((error: IgraphQLErrorsObject) =>
            of(updateMyPasswordFailure({ error })),
          ),
        ),
      ),
    ),
  );

  loadWorkspaceUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWorkspaceUsers),
      switchMap(() =>
        this.usersService.getUsers().pipe(
          map((users) => loadWorkspaceUsersSuccess({ users })),
          catchError((error: unknown) =>
            of(loadWorkspaceUsersFailure({ error })),
          ),
        ),
      ),
    ),
  );

  loadProjectUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProjectUsers),
      switchMap(({ projectId }) =>
        this.api.apollo
          .query<QueryRef<Query>>({
            query: GET_PROJECT_USERS,
            variables: { id: projectId },
            fetchPolicy: 'network-only',
          })
          .pipe(
            map(({ data }: any) => {
              // TODO REFACTOR: remove any
              return loadProjectUsersSuccess({ users: data.project.profiles });
            }),
            catchError((error: unknown) =>
              of(loadProjectUsersFailure({ error })),
            ),
          ),
      ),
    ),
  );

  editThreadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editThreadSuccess, loadThreadSuccess),
      map(({ thread }) =>
        loadThreadUsersSuccess({ users: thread.contributors }),
      ),
    ),
  );

  checkInvitationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkInvitationStatus),
      switchMap(({ hash }) =>
        this.usersService.checkInvitationStatus(hash).pipe(
          map(
            (status) =>
              checkInvitationStatusSuccess({
                status,
              }),
            catchError((error: IgraphQLErrorsObject) =>
              of(checkInvitationStatusFailure({ error })),
            ),
          ),
        ),
      ),
    ),
  );
}
