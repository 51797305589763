/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 2/25/21, 4:29 PM
 * Copyright 2021 3D Source
 */

import { gql } from 'apollo-angular';
import { AUTHOR, PROJECT, PROJECT_IN_PROJECT_LIST } from '../variables';

export const GET_PROJECTS = gql`
  query($limit: Int, $skip: Int, $searchQuery: String) {
    projects(limit: $limit, skip: $skip, searchQuery: $searchQuery) {
      total
      items {
        ${PROJECT_IN_PROJECT_LIST}
      }
    }
  }
`;

export const GET_PROJECT = gql`
  query($id: ID!) {
    project(id: $id) {
      ${PROJECT}
    }
  }
`;

export const GET_PROJECT_USERS = gql`
  query($id: ID!) {
    project(id: $id) {
      id
      profiles {
        ${AUTHOR}
      }
    }
  }
`;
