import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  loadCurrentUserRolePermissions,
  loadWorkspaceUsers,
  updateUserProfileInfo,
  updateUserProfileInfoFailure,
  updateUserProfileInfoSuccess,
} from '@common/store/users/users.actions';
import {
  getCurrentUser,
  getCurrentUserRolePermissions,
  getWorkspaceUsersWithoutMe,
} from '@common/store/users/users.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  GQLEditProfileInput,
  GQLProfile,
  GQLProfilePermissions,
  GQLRolesEnum,
} from '@schemas';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isMobile } from '@common/helpers';
import {
  ConfirmModalComponent,
  EconfirmModalType,
} from '@common/modules/confirm-modal/components/confirm-modal/confirm-modal.component';
import { RolesShortenerPipe } from '@common/pipes/roles-shortener/roles-shortener.pipe';
import { OverlayPanel } from 'primeng/overlaypanel';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

export interface ICustomUserProfile extends GQLProfile {
  canBeChanged?: boolean;
  roleChanged?: boolean;
  availableRoles?: GQLRolesEnum[];
}

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: [
    './roles.component.scss',
    '../user-profile/user-profile.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolesComponent implements OnInit, OnDestroy {
  @ViewChild('roleFilter') roleFilter!: ElementRef;

  me$: Observable<GQLProfile | null> = this.store.select(getCurrentUser);
  users$: Observable<GQLProfile[]> = this.store.select(
    getWorkspaceUsersWithoutMe,
  );
  rolePermissions$: Observable<GQLProfilePermissions | null> =
    this.store.select(getCurrentUserRolePermissions);

  initialUsers: ICustomUserProfile[] = [];
  users: ICustomUserProfile[] = [];
  initialSearchQuery = '';
  searchQuery = '';
  isSearchFieldOpen = false;
  isFilterOpen = false;
  isRoleChangerOpen = false;
  rolesFilter: Array<GQLRolesEnum | string | undefined> = [];
  selectedRole: GQLRolesEnum | string | undefined = 'all';
  requestsQueue: GQLEditProfileInput[] = [];

  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public ref: DynamicDialogRef,
    private readonly store: Store,
    private readonly cd: ChangeDetectorRef,
    private readonly actions$: Actions,
    private readonly dialogService: DialogService,
    private readonly rolesShortener: RolesShortenerPipe,
    private readonly renderer: Renderer2,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.roleFilter.nativeElement) {
        this.isFilterOpen = false;
        this.cd.detectChanges();
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(loadCurrentUserRolePermissions());
    this.loadUsers();

    this.actions$
      .pipe(ofType(updateUserProfileInfoSuccess), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.requestsQueue = [];
        this.store.dispatch(loadWorkspaceUsers());
        this.filterUsers();
        this.cd.detectChanges();
      });

    this.actions$
      .pipe(
        ofType(updateUserProfileInfoFailure),
        distinctUntilChanged(isEqual),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(({ profile }) => {
        this.wrongOperation(profile);
      });
  }

  loadUsers(): void {
    combineLatest([this.users$, this.rolePermissions$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([users, res]) => {
        if (res?.canChangeRoles) {
          this.initialUsers = users.map((user: any) => {
            return {
              ...user,
              canBeChanged: res?.canChangeRoles?.includes(user.role),
              availableRoles: res?.canChangeRoles?.filter((role) =>
                user?.availableRoles?.includes(role),
              ),
            };
          });

          if (!this.users.length && this.selectedRole === 'all') {
            this.users = cloneDeep(this.initialUsers);
            this.rolesFilter = [
              'all',
              ...new Set(this.users.map(({ role }) => role)),
            ];
          }
        }
        this.cd.detectChanges();
      });
  }

  toggleSearch(): void {
    this.isSearchFieldOpen = !this.isSearchFieldOpen;
  }

  handleSearch(searchQuery: string) {
    this.searchQuery = searchQuery;
    this.filterUsers();
  }

  isInlineOverlay() {
    return isMobile();
  }

  opDismissableValue() {
    return this.isInlineOverlay() ? false : true;
  }

  toggleFilter(): void {
    this.isFilterOpen = !this.isFilterOpen;
  }

  filterByRole(role: GQLRolesEnum | string | undefined): void {
    this.selectedRole = role;
    this.isFilterOpen = false;
    this.filterUsers();
  }

  filterUsers(): void {
    const filterBySubstring = this.initialUsers.filter((user: GQLProfile) =>
      user?.fullName?.toLowerCase().includes(this.searchQuery.toLowerCase()),
    );

    if (this.searchQuery && this.selectedRole === 'all') {
      this.users = filterBySubstring;
    } else if (this.searchQuery && this.selectedRole !== 'all') {
      this.users = filterBySubstring.filter(
        ({ role }) => role === this.selectedRole,
      );
    } else if (!this.searchQuery && this.selectedRole !== 'all') {
      this.users = this.initialUsers.filter(
        ({ role }) => role === this.selectedRole,
      );
    } else {
      this.users = this.initialUsers;
    }
  }

  toggleOverlayPanel(op: OverlayPanel, event: Event) {
    if (!op.dismissable) {
      op.bindScrollListener();
    }

    if (this.isRoleChangerOpen) {
      op.hide();
      this.isRoleChangerOpen = false;
    } else {
      op.show(event);
      this.isRoleChangerOpen = true;
    }
  }

  changeRole(
    user: ICustomUserProfile,
    role: GQLRolesEnum,
    index: number,
    op: OverlayPanel,
  ): void {
    op.hide();
    const input: GQLEditProfileInput = {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      role,
    };

    const userIndex = this.requestsQueue.findIndex(({ id }) => id === user?.id);

    const initialUserRole = this.initialUsers.find(
      (initialUser) => initialUser.id === user.id,
    )?.role;

    if (userIndex !== -1 && initialUserRole !== role) {
      this.requestsQueue = this.requestsQueue.map((userObj, i) => {
        if (userIndex === i) {
          return { ...userObj, role };
        }
        return userObj;
      });
    } else if (userIndex !== -1 && initialUserRole === role) {
      this.requestsQueue = this.requestsQueue.filter(
        (_userObj, i) => userIndex !== i,
      );
    } else {
      this.requestsQueue = [...this.requestsQueue, input];
    }

    this.users[index].role = role;
    this.users[index].roleChanged = initialUserRole !== role;
    this.isRoleChangerOpen = false;
  }

  close(): void {
    this.ref.close();
  }

  save(): void {
    this.store.dispatch(updateUserProfileInfo({ input: this.requestsQueue }));
  }

  wrongOperation({ firstName, lastName, role }: GQLProfile): void {
    const fullName = `${firstName} ${lastName}`;
    const dialogConfig = {
      data: {
        buttonText: `Ok`,
        text: `Sorry, something went wrong and ${fullName}’s ${this.rolesShortener.transform(
          role,
        )} role has not been changed. Please try again `,
        type: EconfirmModalType.WARNING,
        isCloseButtonVisible: false,
      },
      header: `Role has not been changed`,
      styleClass: 'service-modal  service-modal--warning',
    };

    this.dialogService.open(ConfirmModalComponent, dialogConfig);
  }

  trackByFn(_index: number, item: { id: string }) {
    return item.id;
  }

  isNameEmailSame(user: ICustomUserProfile): boolean {
    return user?.email === user?.fullName;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
