<div class="files" *ngIf="files$ | async as files; else skeleton">
  <div class="files__heading">
    <h3>Files</h3>
    <a
      [routerLink]="['files']"
      *ngIf="!!files?.length"
      data-test-id="filesWidgetSeeMoreButton"
    >
      See more
    </a>
    <div class="files__counter" data-test-id="filesWidgetFilesCounter">
      Files total:
      <strong>{{ files?.length }} files</strong>
    </div>
  </div>
  <div class="files__grid files-grid">
    <ng-container *ngFor="let file of getFilesShortList(files); index as i">
      <app-file-card
        (click)="openFileViewer(file)"
        [file]="file"
        [isListView]="false"
        [filePlace]="filePlace"
        [attr.data-test-id]="'appFileCard-id-' + file?.id"
      >
      </app-file-card>
    </ng-container>
  </div>
</div>
<ng-template #skeleton>
  <app-files-widget-skeleton></app-files-widget-skeleton>
</ng-template>
