/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 3/2/21, 2:37 PM
 * Copyright 2021 3D Source
 */

import { gql } from 'apollo-angular';
import { AUTHOR, THREAD } from '../variables';

export const GET_THREADS = gql`
    query($id: ID!, $limit:Int, $skip:Int, $status: ThreadStatusEnum, $searchQuery: String) {
      project(id: $id) {
        id
        threads(limit: $limit, skip: $skip, status: $status, searchQuery: $searchQuery ) {
          total
          skip
          limit
          items {
          ${THREAD}
          }
        }
      }
    }
`;

export const GET_THREAD = gql`
  query($id: ID!) {
     thread(id: $id) {
           ${THREAD}
     }
  }
`;
